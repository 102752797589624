@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply inline-flex items-center px-4 py-2 border border-transparent font-medium rounded text-[14px] text-white transition ease-in-out duration-150;
}

.btn:focus {
  @apply outline-none border-gray-700 ring-gray-700;
}

.btn:active {
  @apply bg-gray-700;
}

.btn-xs {
  @apply px-2 py-1.5 text-[0.75rem] rounded;
}

.btn-sm {
  @apply px-3 py-1 text-[0.875rem];
}

.btn-lg,
.btn-md {
  @apply px-4 py-2 text-[0.875rem] font-medium;
}

.btn-xl {
  @apply px-6 py-3 text-[1rem] font-medium;
}

.btn-primary {
  @apply bg-primary-green-500;
}

.btn-primary:hover {
  @apply bg-primary-green-300;
}

.btn-primary:focus {
  @apply outline-none bg-primary-green-400 border-primary-green-500 ring-primary-green-500;
}

.btn-primary:active {
  @apply bg-primary-green-400 border-primary-green-500;
}

.btn-primary:disabled {
  @apply bg-primary-grey-100 text-primary-navy-100;
}

.btn-secondary {
  @apply bg-primary-grey-50 border-primary-grey-100 text-primary-navy-500;
}

.btn-secondary:hover {
  @apply bg-primary-grey-100;
}

.btn-secondary:active {
  @apply bg-primary-grey-300 border-primary-grey-100;
}

.btn-secondary:disabled {
  @apply border-0 bg-primary-grey-100 border-0;
}

.btn-tertiary {
  @apply px-0 text-primary-navy-400;
}

.btn-tertiary:hover {
  @apply text-primary-navy-500;
}

.btn-tertiary:focus {
  @apply outline-none;
}

.btn-tertiary:active {
  @apply outline-none;
}

.btn-tertiary:disabled {
  @apply text-primary-grey-100;
}

.btn-transparent {
  @apply bg-white text-black border-gray-200;
}

.btn-transparent:hover {
  @apply bg-primary-green-500 text-white border-transparent;
}

.btn-transparent:focus {
  @apply outline-none border-blue-700 ring-blue-300;
}

.btn-warning {
  @apply bg-semantic-warning-400 text-primary-navy-500;
}

.btn-warning:hover {
  @apply bg-semantic-warning-500;
}

.btn-warning:focus {
  @apply bg-semantic-warning-600 outline-none;
}

.btn-warning:active {
  @apply bg-red-700;
}

.btn-warning:disabled {
  @apply bg-primary-grey-100 text-primary-navy-100;
}

.btn-danger {
  @apply bg-semantic-danger-400;
}

.btn-danger:hover {
  @apply bg-semantic-danger-500;
}

.btn-danger:focus {
  @apply bg-semantic-danger-600 outline-none;
}

.btn-danger:active {
  @apply bg-red-700;
}

.btn-danger:disabled {
  @apply bg-primary-grey-100 text-primary-navy-100;
}

.btn-white {
  @apply bg-white border-gray-300 text-gray-700;
}

.btn-white:hover {
  @apply bg-white text-gray-500;
}

.btn-white:focus {
  @apply outline-none border-blue-300 ring-blue-300;
}

.btn-white:active {
  @apply text-gray-800 bg-gray-50;
}

.btn-success {
  @apply bg-green-600;
}

.btn-success:hover {
  @apply bg-green-500;
}

.btn-success:focus {
  @apply outline-none border-green-700 ring-green-700;
}

.btn-success:active {
  @apply bg-green-700;
}

.btn-emerald {
  @apply bg-emerald-500;
}

.btn-emerald:hover {
  @apply bg-emerald-600;
}

.btn-emerald:focus {
  @apply outline-none border-emerald-600 ring-emerald-300;
}

.btn-emerald:active {
  @apply bg-emerald-600;
}

.form-checkbox {
  @apply block border-gray-300 rounded text-xs cursor-pointer;
}

.form-checkbox:checked,
.form-checkbox:checked:hover,
.form-checkbox:checked:focus {
  @apply bg-primary-green-500;
}

.form-checkbox:focus {
  @apply border-semantic-success-200 ring-0;
}

.form-checkbox:checked:hover,
[type='checkbox']:indeterminate,
[type='checkbox']:indeterminate:hover {
  @apply bg-primary-green-500;
}

.form-radio {
  @apply border-primary-grey-200 text-xs cursor-pointer;
}

.form-radio:checked,
.form-radio:checked:hover,
.form-radio:checked:focus {
  @apply bg-primary-green-500;
}

.form-radio:focus {
  @apply border-0 ring-transparent outline-none shadow-none;
}

.form-radio:checked:hover,
[type='checkbox']:indeterminate,
[type='checkbox']:indeterminate:hover {
  @apply bg-primary-green-500;
}

.form-input,
.form-textarea,
.form-select,
.form-date,
[type='password'] {
  @apply py-1.5 block text-primary-navy-400 bg-primary-grey-50 border-common-stroke rounded text-sm placeholder-gray-400;
}

.inline-input-table {
  @apply m-[-0.8rem] py-1.5 appearance-none bg-none text-primary-navy-400 rounded text-sm border border-neutral-white-500 bg-neutral-white-500 disabled:opacity-100;
}

.inline-input-table:focus {
  @apply border-semantic-success-200 ring-0;
}

.autocomplete {
  @apply block bg-primary-grey-50 border-primary-grey-100 rounded-r-md text-sm;
}

.form-textarea {
  @apply h-fit;
}

.form-input:focus,
.form-textarea:focus,
.form-select:focus,
.autocomplete:focus,
.form-date:focus,
[type='password']:focus {
  @apply border-semantic-success-200 ring-0 placeholder-gray-500;
}

.form-input-error {
  @apply border-red-300 text-red-900 placeholder-red-300;
}

.form-input-error:focus {
  @apply border-red-500 ring-0;
}

.link {
  @apply tracking-0.02 leading-4.75 no-underline;
}

.active-link {
  @apply text-primary-blue-500 underline underline-offset-2;
}

.link:hover {
  @apply text-primary-blue-500 underline underline-offset-2;
}

.filter {
  @apply first:ml-0 ml-2 px-2.5 py-[7.5px] text-xs leading-none rounded hover:bg-neutral-white-500/90 hover:text-primary-navy-500 hover:font-medium outline-none;
}

.filter--first {
  @apply ml-0;
}

.filter--default {
  @apply bg-neutral-white-500 border border-common-stroke font-medium text-secondary-blue rounded-[112px] max-h-8 whitespace-nowrap;
}

.filter--active {
  @apply bg-neutral-white-500 text-secondary-blue font-medium hover:bg-neutral-white-500/60;
}

.filter--reset {
  @apply bg-neutral-white-500/20 text-secondary-blue py-1;
}

.filter--reset:hover {
  @apply hover:bg-neutral-white-500/60 text-secondary-blue;
}

.badge {
  @apply inline-flex items-center rounded-full font-medium text-gray-800;
}

.toggle-focus:focus-within {
  background: rgb(255, 255, 255);
  border: 0.13rem solid #2563eb;
}

.notification-badge {
  @apply inline px-2 py-1 text-xs relative font-medium leading-none text-white bg-semantic-danger-700 rounded-full;
}

input:disabled {
  @apply opacity-50 cursor-not-allowed;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

textarea:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.file-input {
  @apply w-full text-sm text-gray-500 file:cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-400 file:text-white hover:file:bg-gray-300;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible;
}

pre {
  white-space: pre; /* CSS 2.0 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3.0 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -hp-pre-wrap; /* HP Printers */
  word-wrap: break-word; /* IE 5+ */
  font-family: 'Inter UI var', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

/* Pagy tailwind style-sheet */
.pagy-nav,
.pagy-nav-js,
.pagy-combo-nav-js {
  @apply inline-flex;
}

.pagy-nav.pagination,
.pagy-nav-js.pagination,
.pagy-combo-nav-js.pagination {
  @apply border rounded-md;
}

.pagy-nav .page,
.pagy-nav-js .page,
.pagy-combo-nav-js .page,
.pagy-combo-nav-js .pagy-combo-input {
  @apply text-gray-700 border-r px-2 py-2 text-xs font-medium;
}

.pagy-nav .page:hover,
.pagy-nav-js .page:hover {
  @apply text-gray-900;
}

.pagy-nav .disabled,
.pagy-nav-js .disabled,
.pagy-combo-nav-js .disabled {
  @apply cursor-not-allowed;
}

.pagy-nav .active,
.pagy-nav-js .active {
  @apply text-primary-blue-500;
}

.pagy-nav .prev,
.pagy-nav-js .prev,
.pagy-combo-nav-js .prev {
  @apply text-gray-900;
}

.pagy-nav .next,
.pagy-nav-js .next,
.pagy-combo-nav-js .next {
  @apply text-gray-900 border-r border-transparent;
}

/* Hide the arrows for number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar-thumb {
  background: rgb(207, 206, 206);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#hover-scroll {
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 2px, black 2px);
  mask-size: 100% 20001px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 2px, black 2px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

#hover-scroll:hover {
  -webkit-mask-position: left top;
}

#hover-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 2px;
  width: 2px;
}

#hover-scroll:hover::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
}

#modal-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

#modal-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.h1 {
  @apply text-25 leading-4.75 space-x-0 space-y-0 font-medium;
}

.h2 {
  @apply text-xl leading-none leading-4.75 space-x-0 space-y-0 font-medium;
}

.h3 {
  @apply text-base leading-4.75 space-x-0 space-y-0 font-medium;
}

/* Overriding default text styles  */

.text-base {
  @apply leading-5.75 space-x-0 space-y-0;
}

/* .text-default {
  @apply text-sm leading-5.75 space-x-0 space-y-0;
} */

.text-xs {
  @apply leading-5.75 space-x-0 space-y-0;
}

.text-sm {
  @apply leading-5.75 space-x-0 space-y-0;
}

.text-link-default {
  @apply text-sm;
}

.text-link-small {
  @apply text-xs;
}

.text-link-xs {
  @apply text-10 leading-5.75 space-x-0 space-y-0 font-normal;
}

.link-base {
  @apply text-13 leading-5.75 space-x-0 space-y-0 font-medium text-primary-blue-500;
}

.link-sm {
  @apply text-xs font-medium text-primary-blue-500;
}

.link-xs {
  @apply text-10 leading-5.75 space-x-0 space-y-0 font-medium text-primary-blue-500;
}

.button {
  @apply text-13 leading-5.75 space-x-0.2 space-y-0 font-medium;
}

.form-input-table {
  @apply border-x-0 border-0 outline-none bg-none rounded-none text-xs;
}

/* Styles for HTML templates. Those were not working in Tailwind */
.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-2 {
  grid-column-start: 2;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

/* Finish styles for HTML templates */
.form-select__input:focus {
  @apply focus:ring-0;
}

.mentions {
  @apply bg-[#F5F7F8] w-full h-full px-3 py-4;
}

.mentions__input {
  @apply py-1.5 block text-primary-navy-400 bg-primary-grey-50 border-common-stroke rounded text-sm placeholder-gray-400;
}

.mentions__input:focus {
  @apply border-semantic-success-200 ring-0 placeholder-gray-500;
}

.mentions__suggestions__list {
  @apply absolute top-4 bg-white border border-[#e6f1ff] rounded text-sm w-fit inline-block;
}

.mentions__suggestions__item {
  @apply px-2 py-1 border-b border-gray-200 text-primary-navy-400;
}

.mentions__suggestions__item--focused {
  background-color: #b0d3ff;
}

.text-archived {
  color: #99abba;
}

.stroke {
  @apply border border-common-stroke;
}
