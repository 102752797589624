.html-docs-container {
  input[type='text'],
  input[type='date'],
  input[type='number'],
  input[type='button'],
  textarea {
    font-size: 0.75rem;
  }
}

.document-inline-input-table {
  input[type='text'],
  input[type='date'],
  input[type='number'],
  input[type='button'],
  textarea {
    background-color: inherit;
    resize: none;
    overflow: hidden;
  }
}

.tableFixHead {
  overflow: auto;
}

.tableFixHead th {
  position: sticky;
  top: 0;
}

.sticky-table-header {
  position: sticky;
  top: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.required:after {
  content: ' *';
  color: red;
}

.intercom-lightweight-app {
  z-index: 20 !important;
}
